import './parameters.css';
import { API } from "./../../services/api/api";
// import Box from '@mui/material/Box';
// import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect } from 'react';
import { Card, CardHeader, CardContent, IconButton, FormControlLabel, Checkbox } from '@mui/material';
import { Typography } from '@mui/material';
import { compareJSON, getEmptyPlatform, isAllowed, isAllowedMaintainer, isNullUndefinedEmpty } from "./../../utils/utils";
import Grid from '@mui/material/Grid';
import { Formik, Form, Field } from 'formik';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import ExposureIcon from '@mui/icons-material/Exposure';
import InsightsIcon from '@mui/icons-material/Insights';
import Groups2Icon from '@mui/icons-material/Groups2';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import PetsIcon from '@mui/icons-material/Pets';
import {TextField} from '@mui/material';
import Box from '@mui/material/Box';
import { toast } from 'react-toastify';
import { Controller } from 'react-hook-form';
import { ComponentPropsToStylePropsMap } from '@aws-amplify/ui-react';


export default function Parameters({user}) {

    const [population_concerned, setPopulationConcernedData] = React.useState([]);
    const [plateforms, setPlateformsData] = React.useState([]);
    const [animals, setAnimalsData] = React.useState([]);
    const [uncertainty_factors, setUncertaintyFactors] = React.useState([]);
    const [cramer_class, setCramerClass] = React.useState([]);
    const [human_route_exposure, setHumanRouteExposure] = React.useState([]);
    const [animal_route_administration, setAnimalRouteAdministration] = React.useState([]);
    const [loading, setLoading] = React.useState({});
    const [edit, setEdit] = React.useState({});
    const [allowed, setAllowed] = React.useState(false);



    useEffect(() => {
        const fetchAllowed = async() => {
            let allow = await isAllowedMaintainer(user);
            setAllowed(allow);
        }

        const fetchPopulationConcernedData = async () => {
            try{
                setLoading(loading => ({...loading, ...{['population_concerned'] : true}}));
                let result = await API.getPopulationConcerned();  
                let map = result;
                map.map(x => edit[x._id] = false);
                setPopulationConcernedData(map);
                setLoading(loading => ({...loading, ...{['population_concerned'] : false}}));
            }catch(ex){
                toast.warn("WARN ! impossible to parse population_concerned !");
            }
        };
        
        const fetchAnimalsData = async () => {
            try{
                setLoading(loading => ({...loading, ...{['animals'] : true}}));
                let result = await API.getAnimals();  
                let map = result;
                map.map(x => edit[x._id] = false);
                setAnimalsData(map);
                setLoading(loading => ({...loading, ...{['animals'] : false}}));
            }catch(ex){
                toast.warn("WARN ! impossible to parse animals !");
            }
        }; 

        const fetchUncertaintyFactors = async () => {
            try{
                setLoading(loading => ({...loading, ...{['uncertainty_factors'] : true}}));
                let result = await API.getUncertaintyFactors();  
                let map = result;
                map.map(x => edit[x._id] = false);
                setUncertaintyFactors(map);
                setLoading(loading => ({...loading, ...{['uncertainty_factors'] : false}}));
            }catch(ex){
                toast.warn("WARN ! impossible to parse uncertainty factors !");
            }
        };   

        const fetchCramerClass = async () => {
            try{
                setLoading(loading => ({...loading, ...{['cramer_class'] : true}}));
                let result = await API.getCramerClass();  
                let map = result;
                map.map(x => edit[x._id] = false);
                setCramerClass(map);
                setLoading(loading => ({...loading, ...{['cramer_class'] : false}}));
            }catch(ex){
                toast.warn("WARN ! impossible to parse Cramer CLASS !");
            }
        };  

        const fetchHumanRouteExposure = async () => {
            try{
                setLoading(loading => ({...loading, ...{['human_route_exposure'] : true}}));
                let result = await API.getHumanRouteExposure();  
                let map = result;
                map.map(x => edit[x._id] = false);
                setHumanRouteExposure(map);
                setLoading(loading => ({...loading, ...{['human_route_exposure'] : false}}));
            }catch(ex){
                toast.warn("WARN ! impossible to parse Human Route of Exposure !");
            }
        };

        const fetchAnimalRouteAdministration = async () => {
            try{
                setLoading(loading => ({...loading, ...{['animal_route_administration'] : true}}));
                let result = await API.getAnimalRouteAdministration();  
                let map = result;
                map.map(x => edit[x._id] = false);
                setAnimalRouteAdministration(map);
                setLoading(loading => ({...loading, ...{['animal_route_administration'] : false}}));
            }catch(ex){
                toast.warn("WARN ! impossible to parse Animal Route of Administration !");
            }
        };
        
        const fetchPlateformsData = async () => {
            try{
                setLoading(loading => ({...loading, ...{['plateforms'] : true}}));
                let result = await API.getPlateforms();  
                let map = result;
                map.map(x => edit[x._id] = false);
                setPlateformsData(map);
                setLoading(loading => ({...loading, ...{['plateforms'] : false}}));
            }catch(ex){
                toast.warn("WARN ! impossible to parse plateforms !");
            }
        };   
        
        fetchAllowed();
        fetchPopulationConcernedData();
        fetchAnimalsData();
        fetchUncertaintyFactors();
        fetchCramerClass();
        fetchHumanRouteExposure();
        fetchAnimalRouteAdministration();
        fetchPlateformsData();
        //handleClear();
    }, []);

    const handleEdit = (name) => {
        setEdit(edit => ({...edit, ...{[`${name}`] : true}}));
    };

    const handleClear = (name) => {
        if(name === undefined){
            setPlateformsData(current => current.filter(c => {
                return c._id !== undefined;
            }));
        }else{
            setEdit(edit => ({...edit, ...{[`${name}`] : false}}));
        }
    };

    const handleDelete = async (values) => {

        const audit = {element:values.name, user:user.username, differences: {"action": "delete", "collection": "plateforms", "delete_id": values._id}}
        let p = await API.deletePlateform(values, audit);
        //console.log(p);
        if(!isNullUndefinedEmpty(p._id)){
            let new_platforms = [...plateforms];
            new_platforms.splice(plateforms.findIndex(x => x._id === p._id), 1);
            setPlateformsData(new_platforms);
        }
    }
  
    const handleSubmitPlateform = async (values) => {

        let plateform = null;
        
        // handle new platform
        if(values._id === undefined){
            let index = plateforms.findIndex(x => x._id === undefined);
            plateforms[index] = values;
            plateform = values;
        }else{
            plateform = plateforms.find(x => x._id === values._id);
        }

        if(plateform !== null && (plateform._id === undefined || JSON.stringify(plateform) !== JSON.stringify(values))){
            // const differences = compareJSON(plateform, values);
            // API.audit(differences, plateform.name, user.username);
            console.log(plateform);
            const audit = {element:{_key: plateform._key, name : plateform.name}, user:user.username, differences: compareJSON(plateform, values)}
            let p = await API.savePlateform(values, audit);
            let new_platforms = [...plateforms]

            if(isNullUndefinedEmpty(values._id)){
                new_platforms[plateforms.length-1] = p;
            }else{
                let index = new_platforms.findIndex(x => x._id === values._id);
                new_platforms[index] = p;
            }
            
            setPlateformsData(new_platforms);
        }

    };

    const isNewMode = () => {
        // console.log(plateforms.some(x => x._id === undefined));
        return plateforms.some(x => x._id === undefined);
    }

    const handleSubmitAnimals = (values) => {

        let animal = animals.filter(x => x.name === values.name);
        if(animal != null && JSON.stringify(animal) !== JSON.stringify(values)){
            // const differences = compareJSON(plateform, values);
            // API.audit(differences, plateform.name, user.username);
            const audit = {element:{name:animal.name}, user:user.username, differences: compareJSON(animal, values)}
            API.saveAnimals(values, audit);
        }

    };

    const handleSubmitPopulationConcerned = (values) => {

        let p_c = population_concerned.filter(x => x.name === values.name);
        if(JSON.stringify(p_c) !== JSON.stringify(values)){
            // const differences = compareJSON(master_data, values);
            // API.audit(differences, master_data.name, user.username);
            const audit = {element:{name:p_c.name}, user:user.username, differences: compareJSON(p_c, values)}
            API.savePopulationConcerned(values, audit);
        }
    };

    const handleSubmitUncertaintyFactors = (values) => {

        let u_f = uncertainty_factors.filter(x => x.name === values.name);
        if(u_f != null && JSON.stringify(u_f) !== JSON.stringify(values)){
            // const differences = compareJSON(plateform, values);
            // API.audit(differences, plateform.name, user.username);
            const audit = {element:{name:u_f.name}, user:user.username, differences: compareJSON(u_f, values)}
            API.saveUncertaintyFactors(values, audit);
        }

    };

    const handleSubmitHumanRouteExposure = (values) => {

        let h_r_e = human_route_exposure.filter(x => x.name === values.name);
        if(h_r_e != null && JSON.stringify(h_r_e) !== JSON.stringify(values)){
            // const differences = compareJSON(plateform, values);
            // API.audit(differences, plateform.name, user.username);
            const audit = {element:{name:h_r_e.name}, user:user.username, differences: compareJSON(h_r_e, values)}
            API.saveHumanRouteExposure(values, audit);
        }

    };

    const handleSubmitAnimalRouteAdministration = (values) => {

        let a_r_a = animal_route_administration.filter(x => x.name === values.name);
        if(a_r_a != null && JSON.stringify(a_r_a) !== JSON.stringify(values)){
            // const differences = compareJSON(plateform, values);
            // API.audit(differences, plateform.name, user.username);
            const audit = {element:{name:a_r_a.name}, user:user.username, differences: compareJSON(a_r_a, values)}
            API.saveAnimalRouteAdministration(values, audit);
        }

    };

    const handleSubmitCramerClass = (values) => {

        let c_c = cramer_class.filter(x => x.name === values.name);
        if(c_c != null && JSON.stringify(c_c) !== JSON.stringify(values)){
            // const differences = compareJSON(plateform, values);
            // API.audit(differences, plateform.name, user.username);
            const audit = {element:{name:c_c.name}, user:user.username, differences: compareJSON(c_c, values)}
            API.saveCramerClass(values, audit);
        }

    };

    const handleNewPlatform = () => {

        let newPlatform = getEmptyPlatform();
        // newPlatform.name = "NEW PLATFORM"
        setPlateformsData(oldPlatforms => [...oldPlatforms, newPlatform]);
    };

    const isPlatformEditable = (platform) => {
     
        if(edit[platform._id]){
            return true;
        }else{
            if(isNullUndefinedEmpty(platform._id)){
                return true;
            }else{
                return false;
            }
        }
    }

    return (
        <>
                <div className="Population_concerned">
                    <Card key="main_population_concerned">
                        <CardHeader
                            avatar={<InsightsIcon fontSize="large" />}
                            title={<Typography variant="h4">Population Concerned</Typography>}
                        />
                        <CardContent> 
                            {!loading.population_concerned ? (
                                <Grid container spacing={2}> 
                                <>
                                {population_concerned.map((p_c, index) => ( 
                                    <Grid key={p_c.name} item xs={6}>      
                                    <Formik
                                    enableReinitialize={true}
                                    initialValues={p_c}
                                    //validationSchema={ValidationSchema}
                                    onSubmit={(values, actions) => {
                                        handleSubmitPopulationConcerned(values);
                                        handleClear(values._id);
                                    }}
                                    >
                                    {datas => (
                                        <Form onSubmit={datas.handleSubmit}>
                                            <Card>
                                                <CardHeader
                                                avatar={
                                                    <Groups2Icon fontSize="large" />
                                                }
                                                action={
                                                    <>
                                                    {
                                                        allowed ? (
                                                            !edit[datas.values._id] ? (
                                                                <IconButton aria-label="edit" onClick={() => {handleEdit(datas.values._id);}}>
                                                                    <EditIcon />
                                                                </IconButton>
                                                            ) : (
                                                            <>
                                                                <IconButton aria-label="done" type="submit">
                                                                    <DoneIcon />
                                                                </IconButton>

                                                                <IconButton aria-label="clear" onClick={() => {datas.resetForm(); handleClear(datas.values._id);}}>
                                                                    <ClearIcon />
                                                                </IconButton>
                                                            </>
                                                            )
                                                        ) : null
                                                    }                                   
                                                    </>
                                                }
                                                title={<Typography variant="h5">{datas.values.label}</Typography>}
                                                // subheader={}
                                                />
                                                <CardContent>
                                                    <Box>
                                                        <Grid container spacing={2}> 
                                                            {/* <TextField
                                                            disabled={!edit[datas.values._id]}
                                                            type="text"
                                                            label="Name"
                                                            onChange={datas.handleChange}
                                                            onBlur={datas.handleBlur}
                                                            value={datas.values.name}
                                                            name="name"
                                                            />
                                                            {datas.errors.name && <div id="fb_name">{datas.errors.name}</div>} */}

                                                            <Grid item xs={6}> 
                                                                <TextField
                                                                fullWidth
                                                                disabled={!edit[datas.values._id]}
                                                                type="text"
                                                                label="Human exposure per day (hours)"
                                                                onChange={datas.handleChange}
                                                                onBlur={datas.handleBlur}
                                                                value={datas.values.human_exposure_per_day || ''}
                                                                name="human_exposure_per_day"
                                                                />
                                                                {datas.errors.human_exposure_per_day && <div id="fb_human_exposure_per_day">{datas.errors.human_exposure_per_day}</div>}
                                                            </Grid>

                                                            <Grid item xs={6}>                                                    
                                                                <TextField
                                                                fullWidth
                                                                disabled={!edit[datas.values._id]}
                                                                type="text"
                                                                label="Human exposure per week (days)"
                                                                onChange={datas.handleChange}
                                                                onBlur={datas.handleBlur}
                                                                value={datas.values.human_exposure_per_week || ''}
                                                                name="human_exposure_per_week"
                                                                />
                                                                {datas.errors.human_exposure_per_week && <div id="fb_human_exposure_per_week">{datas.errors.human_exposure_per_week}</div>}
                                                            </Grid>


                                                            <Grid item xs={6}>
                                                                <TextField
                                                                fullWidth
                                                                disabled={!edit[datas.values._id]}
                                                                type="text"
                                                                label="Human daily inhalation rate (m³/person)"
                                                                onChange={datas.handleChange}
                                                                onBlur={datas.handleBlur}
                                                                value={datas.values.human_daily_inhalation_rate || ''}
                                                                name="human_daily_inhalation_rate"
                                                                />
                                                                {datas.errors.human_daily_inhalation_rate && <div id="fb_human_daily_inhalation_rate">{datas.errors.human_daily_inhalation_rate}</div>}
                                                            </Grid>

                                                            <Grid item xs={6}>
                                                                <TextField
                                                                fullWidth
                                                                disabled={!edit[datas.values._id]}
                                                                type="text"
                                                                label="Human body weight (kg)"
                                                                onChange={datas.handleChange}
                                                                onBlur={datas.handleBlur}
                                                                value={datas.values.human_body_weight || ''}
                                                                name="human_body_weight"
                                                                />
                                                                {datas.errors.human_body_weight && <div id="fb_human_body_weight">{datas.errors.human_body_weight}</div>}
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Form>
                                    )}
                                    </Formik> 
                                </Grid> 
                            ))}
                            </>
                            </Grid> 
                        ) : <CircularProgress /> }                
                        </CardContent>
                    </Card>
                </div>

                <div className="Animals">
                    <Card key=" main_animals" sx={{mt:2}}>
                        <CardHeader
                            avatar={<InsightsIcon fontSize="large" />}
                            title={<Typography variant="h4">Animals</Typography>}
                        />
                        <CardContent> 
                            {!loading.animals ? (
                                <Grid container spacing={2}> 
                                <>
                                {animals.map((animal, index) => ( 
                                    <Grid key={animal.name} item xs={6}>      
                                    <Formik
                                    enableReinitialize={true}
                                    initialValues={animal}
                                    //validationSchema={ValidationSchema}
                                    onSubmit={(values, actions) => {
                                        handleSubmitAnimals(values);
                                        handleClear(values._id);
                                    }}
                                    >
                                    {datas => (
                                        <Form onSubmit={datas.handleSubmit}>
                                            <Card>
                                                <CardHeader
                                                avatar={
                                                    <PetsIcon fontSize="large" />
                                                }
                                                action={
                                                    <>
                                                    {
                                                        allowed ? (
                                                            !edit[datas.values._id] ? (
                                                                <IconButton aria-label="edit" onClick={() => {handleEdit(datas.values._id);}}>
                                                                    <EditIcon />
                                                                </IconButton>
                                                            ) : (
                                                            <>
                                                                <IconButton aria-label="done" type="submit">
                                                                    <DoneIcon />
                                                                </IconButton>

                                                                <IconButton aria-label="clear" onClick={() => {datas.resetForm(); handleClear(datas.values._id);}}>
                                                                    <ClearIcon />
                                                                </IconButton>
                                                            </>
                                                            )
                                                        ) : null
                                                    }                                   
                                                    </>
                                                }
                                                title={<Typography variant="h5">{datas.values.label}</Typography>}
                                                // subheader={}
                                                />
                                                <CardContent>
                                                    <Box>
                                                        <Grid container spacing={2}> 
                                                            {/* <TextField
                                                            disabled={!edit[datas.values._id]}
                                                            type="text"
                                                            label="Name"
                                                            onChange={datas.handleChange}
                                                            onBlur={datas.handleBlur}
                                                            value={datas.values.name}
                                                            name="name"
                                                            />
                                                            {datas.errors.name && <div id="fb_name">{datas.errors.name}</div>} */}

                                                            <Grid item xs={6}> 
                                                                <TextField
                                                                fullWidth
                                                                disabled={!edit[datas.values._id]}
                                                                type="text"
                                                                label="Allometric Scaling"
                                                                onChange={datas.handleChange}
                                                                onBlur={datas.handleBlur}
                                                                value={datas.values.allometric_scaling || ''}
                                                                name="allometric_scaling"
                                                                />
                                                                {datas.errors.allometric_scaling && <div id="fb_allometric_scaling">{datas.errors.allometric_scaling}</div>}
                                                            </Grid>

                                                            <Grid item xs={6}>                                                    
                                                                <TextField
                                                                fullWidth
                                                                disabled={!edit[datas.values._id]}
                                                                type="text"
                                                                label="Standard Respiratory Volume (m3/min kgBW)"
                                                                onChange={datas.handleChange}
                                                                onBlur={datas.handleBlur}
                                                                value={datas.values.standard_respiratory_volume || ''}
                                                                name="standard_respiratory_volume"
                                                                />
                                                                {datas.errors.standard_respiratory_volume && <div id="fb_standard_respiratory_volume">{datas.errors.standard_respiratory_volume}</div>}
                                                            </Grid>

                                                        </Grid>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Form>
                                    )}
                                    </Formik> 
                                </Grid> 
                            ))}
                            </>
                            </Grid> 
                        ) : <CircularProgress /> }                
                        </CardContent>
                    </Card>
                </div>

                <div className="Uncertainty_factors">
                    <Card key=" main_uncertainty_factors" sx={{mt:2}}>
                        <CardHeader
                            avatar={<InsightsIcon fontSize="large" />}
                            title={<Typography variant="h4">Uncertainty Factors</Typography>}
                        />
                        <CardContent> 
                            {!loading.uncertainty_factors ? (
                                <Grid container spacing={2}> 
                                <>
                                    {uncertainty_factors.map((u_f, index) => ( 
                                        <Grid key={u_f.name} item xs={6}>
                                            {u_f.name === "UFL" ? (      
                                                <Formik
                                                enableReinitialize={true}
                                                initialValues={u_f}
                                                //validationSchema={ValidationSchema}
                                                onSubmit={(values, actions) => {
                                                    handleSubmitUncertaintyFactors(values);
                                                    handleClear(values._id);
                                                }}
                                                >
                                                {datas => (
                                                    <Form onSubmit={datas.handleSubmit}>
                                                        <Card>
                                                            <CardHeader
                                                            avatar={
                                                                <Groups2Icon fontSize="large" />
                                                            }
                                                            action={
                                                                <>
                                                                {
                                                                    allowed ? (
                                                                        !edit[datas.values._id] ? (
                                                                            <IconButton aria-label="edit" onClick={() => {handleEdit(datas.values._id);}}>
                                                                                <EditIcon />
                                                                            </IconButton>
                                                                        ) : (
                                                                        <>
                                                                            <IconButton aria-label="done" type="submit">
                                                                                <DoneIcon />
                                                                            </IconButton>

                                                                            <IconButton aria-label="clear" onClick={() => {datas.resetForm(); handleClear(datas.values._id);}}>
                                                                                <ClearIcon />
                                                                            </IconButton>
                                                                        </>
                                                                        )
                                                                    ) : null 
                                                                }                                   
                                                                </>
                                                            }
                                                            title={<Typography variant="h5">{datas.values.name}</Typography>}
                                                            // subheader={}
                                                            />
                                                            <CardContent>
                                                                <Box>
                                                                    <Grid container spacing={2}> 
                                                                        {/* <TextField
                                                                        disabled={!edit[datas.values._id]}
                                                                        type="text"
                                                                        label="Name"
                                                                        onChange={datas.handleChange}
                                                                        onBlur={datas.handleBlur}
                                                                        value={datas.values.name}
                                                                        name="name"
                                                                        />
                                                                        {datas.errors.name && <div id="fb_name">{datas.errors.name}</div>} */}

                                                                        <Grid item xs={6}> 
                                                                            <TextField
                                                                            fullWidth
                                                                            disabled={!edit[datas.values._id]}
                                                                            type="text"
                                                                            label="NOAEL"
                                                                            onChange={datas.handleChange}
                                                                            onBlur={datas.handleBlur}
                                                                            value={datas.values.NOAEL || ''}
                                                                            name="NOAEL"
                                                                            />
                                                                            {datas.errors.NOAEL && <div id="fb_NOAEL">{datas.errors.NOAEL}</div>}
                                                                        </Grid>

                                                                        <Grid item xs={6}>                                                    
                                                                            <TextField
                                                                            fullWidth
                                                                            disabled={!edit[datas.values._id]}
                                                                            type="text"
                                                                            label="NOEL"
                                                                            onChange={datas.handleChange}
                                                                            onBlur={datas.handleBlur}
                                                                            value={datas.values.NOEL || ''}
                                                                            name="NOEL"
                                                                            />
                                                                            {datas.errors.NOEL && <div id="fb_NOEL">{datas.errors.NOEL}</div>}
                                                                        </Grid>

                                                                        <Grid item xs={6}>                                                    
                                                                            <TextField
                                                                            fullWidth
                                                                            disabled={!edit[datas.values._id]}
                                                                            type="text"
                                                                            label="LOAEL low effect"
                                                                            onChange={datas.handleChange}
                                                                            onBlur={datas.handleBlur}
                                                                            value={datas.values.LOAEL_low || ''}
                                                                            name="LOAEL_low"
                                                                            />
                                                                            {datas.errors.LOAEL_low && <div id="fb_LOAEL_low">{datas.errors.LOAEL_low}</div>}
                                                                        </Grid>

                                                                        <Grid item xs={6}>                                                    
                                                                            <TextField
                                                                            fullWidth
                                                                            disabled={!edit[datas.values._id]}
                                                                            type="text"
                                                                            label="LOAEL mild effect"
                                                                            onChange={datas.handleChange}
                                                                            onBlur={datas.handleBlur}
                                                                            value={datas.values.LOAEL_mild || ''}
                                                                            name="LOAEL_mild"
                                                                            />
                                                                            {datas.errors.LOAEL_mild && <div id="fb_LOAEL_mild">{datas.errors.LOAEL_mild}</div>}
                                                                        </Grid>

                                                                        <Grid item xs={6}>                                                    
                                                                            <TextField
                                                                            fullWidth
                                                                            disabled={!edit[datas.values._id]}
                                                                            type="text"
                                                                            label="LOAEL severe effect"
                                                                            onChange={datas.handleChange}
                                                                            onBlur={datas.handleBlur}
                                                                            value={datas.values.LOAEL_severe || ''}
                                                                            name="LOAEL_severe"
                                                                            />
                                                                            {datas.errors.LOAEL_severe && <div id="fb_LOAEL_severe">{datas.errors.LOAEL_severe}</div>}
                                                                        </Grid>

                                                                        <Grid item xs={6}>                                                    
                                                                            <TextField
                                                                            fullWidth
                                                                            disabled={!edit[datas.values._id]}
                                                                            type="text"
                                                                            label="NOAEC"
                                                                            onChange={datas.handleChange}
                                                                            onBlur={datas.handleBlur}
                                                                            value={datas.values.NOAEC || ''}
                                                                            name="NOAEC"
                                                                            />
                                                                            {datas.errors.NOAEC && <div id="fb_NOAEC">{datas.errors.NOAEC}</div>}
                                                                        </Grid>

                                                                        <Grid item xs={6}>                                                    
                                                                            <TextField
                                                                            fullWidth
                                                                            disabled={!edit[datas.values._id]}
                                                                            type="text"
                                                                            label="NOEC"
                                                                            onChange={datas.handleChange}
                                                                            onBlur={datas.handleBlur}
                                                                            value={datas.values.NOEC || ''}
                                                                            name="NOEC"
                                                                            />
                                                                            {datas.errors.NOEC && <div id="fb_NOEC">{datas.errors.NOEC}</div>}
                                                                        </Grid>

                                                                        <Grid item xs={6}>                                                    
                                                                            <TextField
                                                                            fullWidth
                                                                            disabled={!edit[datas.values._id]}
                                                                            type="text"
                                                                            label="BMD"
                                                                            onChange={datas.handleChange}
                                                                            onBlur={datas.handleBlur}
                                                                            value={datas.values.BMD || ''}
                                                                            name="BMD"
                                                                            />
                                                                            {datas.errors.BMD && <div id="fb_BMD">{datas.errors.BMD}</div>}
                                                                        </Grid>

                                                                    </Grid>
                                                                </Box>
                                                            </CardContent>
                                                        </Card>
                                                    </Form>
                                                )}
                                                </Formik> 
                                            ) : null}

                                            {u_f.name === "UFA constant" ? (      
                                                <Formik
                                                enableReinitialize={true}
                                                initialValues={u_f}
                                                //validationSchema={ValidationSchema}
                                                onSubmit={(values, actions) => {
                                                    handleSubmitUncertaintyFactors(values);
                                                    handleClear(values._id);
                                                }}
                                                >
                                                {datas => (
                                                    <Form onSubmit={datas.handleSubmit}>
                                                        <Card>
                                                            <CardHeader
                                                            avatar={
                                                                <Groups2Icon fontSize="large" />
                                                            }
                                                            action={
                                                                <>
                                                                {
                                                                    allowed ? (
                                                                        !edit[datas.values._id] ? (
                                                                            <IconButton aria-label="edit" onClick={() => {handleEdit(datas.values._id);}}>
                                                                                <EditIcon />
                                                                            </IconButton>
                                                                        ) : (
                                                                        <>
                                                                            <IconButton aria-label="done" type="submit">
                                                                                <DoneIcon />
                                                                            </IconButton>

                                                                            <IconButton aria-label="clear" onClick={() => {datas.resetForm(); handleClear(datas.values._id);}}>
                                                                                <ClearIcon />
                                                                            </IconButton>
                                                                        </>
                                                                        )
                                                                    ) : null
                                                                }                                   
                                                                </>
                                                            }
                                                            title={<Typography variant="h5">{datas.values.name}</Typography>}
                                                            // subheader={}
                                                            />
                                                            <CardContent>
                                                                <Box>
                                                                    <Grid container spacing={2}> 
                                                                        {/* <TextField
                                                                        disabled={!edit[datas.values._id]}
                                                                        type="text"
                                                                        label="Name"
                                                                        onChange={datas.handleChange}
                                                                        onBlur={datas.handleBlur}
                                                                        value={datas.values.name}
                                                                        name="name"
                                                                        />
                                                                        {datas.errors.name && <div id="fb_name">{datas.errors.name}</div>} */}

                                                                        <Grid item xs={6}> 
                                                                            <TextField
                                                                            fullWidth
                                                                            disabled={!edit[datas.values._id]}
                                                                            type="text"
                                                                            label="Inhalation value"
                                                                            onChange={datas.handleChange}
                                                                            onBlur={datas.handleBlur}
                                                                            value={datas.values.inhalation_value || ''}
                                                                            name="inhalation_value"
                                                                            />
                                                                            {datas.errors.inhalation_value && <div id="fb_inhalation_value">{datas.errors.inhalation_value}</div>}
                                                                        </Grid>

                                                                        <Grid item xs={6}>                                                    
                                                                            <TextField
                                                                            fullWidth
                                                                            disabled={!edit[datas.values._id]}
                                                                            type="text"
                                                                            label="Oral value"
                                                                            onChange={datas.handleChange}
                                                                            onBlur={datas.handleBlur}
                                                                            value={datas.values.oral_value || ''}
                                                                            name="oral_value"
                                                                            />
                                                                            {datas.errors.oral_value && <div id="fb_oral_value">{datas.errors.oral_value}</div>}
                                                                        </Grid>

                                                                    </Grid>
                                                                </Box>
                                                            </CardContent>
                                                        </Card>
                                                    </Form>
                                                )}
                                                </Formik> 
                                            ) : null}

                                            {u_f.name === "UFH" ? (      
                                                <Formik
                                                enableReinitialize={true}
                                                initialValues={u_f}
                                                //validationSchema={ValidationSchema}
                                                onSubmit={(values, actions) => {
                                                    handleSubmitUncertaintyFactors(values);
                                                    handleClear(values._id);
                                                }}
                                                >
                                                {datas => (
                                                    <Form onSubmit={datas.handleSubmit}>
                                                        <Card>
                                                            <CardHeader
                                                            avatar={
                                                                <Groups2Icon fontSize="large" />
                                                            }
                                                            action={
                                                                <>
                                                                {
                                                                    allowed ? (
                                                                        !edit[datas.values._id] ? (
                                                                            <IconButton aria-label="edit" onClick={() => {handleEdit(datas.values._id);}}>
                                                                                <EditIcon />
                                                                            </IconButton>
                                                                        ) : (
                                                                        <>
                                                                            <IconButton aria-label="done" type="submit">
                                                                                <DoneIcon />
                                                                            </IconButton>

                                                                            <IconButton aria-label="clear" onClick={() => {datas.resetForm(); handleClear(datas.values._id);}}>
                                                                                <ClearIcon />
                                                                            </IconButton>
                                                                        </>
                                                                        )
                                                                    ) : null
                                                                }                                   
                                                                </>
                                                            }
                                                            title={<Typography variant="h5">{datas.values.name}</Typography>}
                                                            // subheader={}
                                                            />
                                                            <CardContent>
                                                                <Box>
                                                                    <Grid container spacing={2}> 
                                                                        {/* <TextField
                                                                        disabled={!edit[datas.values._id]}
                                                                        type="text"
                                                                        label="Name"
                                                                        onChange={datas.handleChange}
                                                                        onBlur={datas.handleBlur}
                                                                        value={datas.values.name}
                                                                        name="name"
                                                                        />
                                                                        {datas.errors.name && <div id="fb_name">{datas.errors.name}</div>} */}

                                                                        <Grid item xs={6}> 
                                                                            <TextField
                                                                            fullWidth
                                                                            disabled={!edit[datas.values._id]}
                                                                            type="text"
                                                                            label="General population"
                                                                            onChange={datas.handleChange}
                                                                            onBlur={datas.handleBlur}
                                                                            value={datas.values.general || ''}
                                                                            name="general"
                                                                            />
                                                                            {datas.errors.general && <div id="fb_general">{datas.errors.general}</div>}
                                                                        </Grid>

                                                                        <Grid item xs={6}>                                                    
                                                                            <TextField
                                                                            fullWidth
                                                                            disabled={!edit[datas.values._id]}
                                                                            type="text"
                                                                            label="Workers population"
                                                                            onChange={datas.handleChange}
                                                                            onBlur={datas.handleBlur}
                                                                            value={datas.values.workers || ''}
                                                                            name="workers"
                                                                            />
                                                                            {datas.errors.workers && <div id="workers">{datas.errors.workers}</div>}
                                                                        </Grid>

                                                                    </Grid>
                                                                </Box>
                                                            </CardContent>
                                                        </Card>
                                                    </Form>
                                                )}
                                                </Formik> 
                                            ) : null}

                                            {u_f.name === "UFS" ? (      
                                                <Formik
                                                enableReinitialize={true}
                                                initialValues={u_f}
                                                //validationSchema={ValidationSchema}
                                                onSubmit={(values, actions) => {
                                                    handleSubmitUncertaintyFactors(values);
                                                    handleClear(values._id);
                                                }}
                                                >
                                                {datas => (
                                                    <Form onSubmit={datas.handleSubmit}>
                                                        <Card>
                                                            <CardHeader
                                                            avatar={
                                                                <Groups2Icon fontSize="large" />
                                                            }
                                                            action={
                                                                <>
                                                                {
                                                                    allowed ? (
                                                                        !edit[datas.values._id] ? (
                                                                            <IconButton aria-label="edit" onClick={() => {handleEdit(datas.values._id);}}>
                                                                                <EditIcon />
                                                                            </IconButton>
                                                                        ) : (
                                                                        <>
                                                                            <IconButton aria-label="done" type="submit">
                                                                                <DoneIcon />
                                                                            </IconButton>

                                                                            <IconButton aria-label="clear" onClick={() => {datas.resetForm(); handleClear(datas.values._id);}}>
                                                                                <ClearIcon />
                                                                            </IconButton>
                                                                        </>
                                                                        )
                                                                    ) : null
                                                                }                                   
                                                                </>
                                                            }
                                                            title={<Typography variant="h5">{datas.values.name}</Typography>}
                                                            // subheader={}
                                                            />
                                                            <CardContent>
                                                                <Box>
                                                                    <Grid container spacing={2}> 
                                                                        {/* <TextField
                                                                        disabled={!edit[datas.values._id]}
                                                                        type="text"
                                                                        label="Name"
                                                                        onChange={datas.handleChange}
                                                                        onBlur={datas.handleBlur}
                                                                        value={datas.values.name}
                                                                        name="name"
                                                                        />
                                                                        {datas.errors.name && <div id="fb_name">{datas.errors.name}</div>} */}

                                                                        <Grid item xs={6}> 
                                                                            <TextField
                                                                            fullWidth
                                                                            disabled={!edit[datas.values._id]}
                                                                            type="text"
                                                                            label="Chronic"
                                                                            onChange={datas.handleChange}
                                                                            onBlur={datas.handleBlur}
                                                                            value={datas.values.chronic || ''}
                                                                            name="chronic"
                                                                            />
                                                                            {datas.errors.chronic && <div id="fb_chronic">{datas.errors.chronic}</div>}
                                                                        </Grid>

                                                                        <Grid item xs={6}>                                                    
                                                                            <TextField
                                                                            fullWidth
                                                                            disabled={!edit[datas.values._id]}
                                                                            type="text"
                                                                            label="Sub-chronic"
                                                                            onChange={datas.handleChange}
                                                                            onBlur={datas.handleBlur}
                                                                            value={datas.values.sub_chronic || ''}
                                                                            name="sub_chronic"
                                                                            />
                                                                            {datas.errors.sub_chronic && <div id="fb_sub_chronic">{datas.errors.sub_chronic}</div>}
                                                                        </Grid>

                                                                        <Grid item xs={6}>                                                    
                                                                            <TextField
                                                                            fullWidth
                                                                            disabled={!edit[datas.values._id]}
                                                                            type="text"
                                                                            label="Sub-acute"
                                                                            onChange={datas.handleChange}
                                                                            onBlur={datas.handleBlur}
                                                                            value={datas.values.sub_acute || ''}
                                                                            name="sub_acute"
                                                                            />
                                                                            {datas.errors.sub_acute && <div id="fb_sub_acute">{datas.errors.sub_acute}</div>}
                                                                        </Grid>

                                                                    </Grid>
                                                                </Box>
                                                            </CardContent>
                                                        </Card>
                                                    </Form>
                                                )}
                                                </Formik> 
                                            ) : null}
                                        </Grid> 
                                    ))}
                                </>
                                </Grid> 
                            ) : <CircularProgress /> }                
                        </CardContent>
                    </Card>
                </div>

                <div className="Cramer_class">
                    <Card key=" main_cramer_class" sx={{mt:2}}>
                        <CardHeader
                            avatar={<InsightsIcon fontSize="large" />}
                            title={<Typography variant="h4">Cramer Class</Typography>}
                        />
                        <CardContent> 
                            {!loading.cramer_class ? (
                                <Grid container spacing={2}> 
                                <>
                                {cramer_class.map((c_c, index) => ( 
                                    <Grid key={c_c.name} item xs={6}>      
                                    <Formik
                                    enableReinitialize={true}
                                    initialValues={c_c}
                                    //validationSchema={ValidationSchema}
                                    onSubmit={(values, actions) => {
                                        handleSubmitCramerClass(values);
                                        handleClear(values._id);
                                    }}
                                    >
                                    {datas => (
                                        <Form onSubmit={datas.handleSubmit}>
                                            <Card>
                                                <CardHeader
                                                avatar={
                                                    <Groups2Icon fontSize="large" />
                                                }
                                                action={
                                                    <>
                                                    {
                                                        allowed ? (
                                                            !edit[datas.values._id] ? (
                                                                <IconButton aria-label="edit" onClick={() => {handleEdit(datas.values._id);}}>
                                                                    <EditIcon />
                                                                </IconButton>
                                                            ) : (
                                                            <>
                                                                <IconButton aria-label="done" type="submit">
                                                                    <DoneIcon />
                                                                </IconButton>

                                                                <IconButton aria-label="clear" onClick={() => {datas.resetForm(); handleClear(datas.values._id);}}>
                                                                    <ClearIcon />
                                                                </IconButton>
                                                            </>
                                                            )
                                                        ) : null
                                                    }                                   
                                                    </>
                                                }
                                                title={<Typography variant="h5">{datas.values.name}</Typography>}
                                                // subheader={}
                                                />
                                                <CardContent>
                                                    <Box>
                                                        <Grid container spacing={2}> 
                                                            {/* <TextField
                                                            disabled={!edit[datas.values._id]}
                                                            type="text"
                                                            label="Name"
                                                            onChange={datas.handleChange}
                                                            onBlur={datas.handleBlur}
                                                            value={datas.values.name}
                                                            name="name"
                                                            />
                                                            {datas.errors.name && <div id="fb_name">{datas.errors.name}</div>} */}

                                                            <Grid item xs={6}> 
                                                                <TextField
                                                                fullWidth
                                                                disabled={!edit[datas.values._id]}
                                                                type="text"
                                                                label="TTC inhalation (mg/day)"
                                                                onChange={datas.handleChange}
                                                                onBlur={datas.handleBlur}
                                                                value={datas.values.ttc_inhalation || ''}
                                                                name="ttc_inhalation"
                                                                />
                                                                {datas.errors.ttc_inhalation && <div id="fb_ttc_inhalation">{datas.errors.ttc_inhalation}</div>}
                                                            </Grid>

                                                            <Grid item xs={6}>                                                    
                                                                <TextField
                                                                fullWidth
                                                                disabled={!edit[datas.values._id]}
                                                                type="text"
                                                                label="TTC oral (mg/day)"
                                                                onChange={datas.handleChange}
                                                                onBlur={datas.handleBlur}
                                                                value={datas.values.ttc_oral || ''}
                                                                name="ttc_oral"
                                                                />
                                                                {datas.errors.ttc_oral && <div id="fb_ttc_oral">{datas.errors.ttc_oral}</div>}
                                                            </Grid>

                                                        </Grid>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Form>
                                    )}
                                    </Formik> 
                                </Grid> 
                            ))}
                            </>
                            </Grid> 
                        ) : <CircularProgress /> }                
                        </CardContent>
                    </Card>
                </div>

                <div className="Human_route_of_exposure">
                    <Card key=" main_human_route_exposure" sx={{mt:2}}>
                        <CardHeader
                            avatar={<InsightsIcon fontSize="large" />}
                            title={<Typography variant="h4">Human Route of Exposure</Typography>}
                        />
                        <CardContent> 
                            {!loading.human_route_exposure ? (
                                <Grid container spacing={2}> 
                                <>
                                {human_route_exposure.map((h_r_e, index) => ( 
                                    <Grid key={h_r_e.name} item xs={6}>      
                                    <Formik
                                    enableReinitialize={true}
                                    initialValues={h_r_e}
                                    //validationSchema={ValidationSchema}
                                    onSubmit={(values, actions) => {
                                        handleSubmitHumanRouteExposure(values);
                                        handleClear(values._id);
                                    }}
                                    >
                                    {datas => (
                                        <Form onSubmit={datas.handleSubmit}>
                                            <Card>
                                                <CardHeader
                                                avatar={
                                                    <Groups2Icon fontSize="large" />
                                                }
                                                action={
                                                    <>
                                                    {
                                                        allowed ? (
                                                            !edit[datas.values._id] ? (
                                                                <IconButton aria-label="edit" onClick={() => {handleEdit(datas.values._id);}}>
                                                                    <EditIcon />
                                                                </IconButton>
                                                            ) : (
                                                            <>
                                                                <IconButton aria-label="done" type="submit">
                                                                    <DoneIcon />
                                                                </IconButton>

                                                                <IconButton aria-label="clear" onClick={() => {datas.resetForm(); handleClear(datas.values._id);}}>
                                                                    <ClearIcon />
                                                                </IconButton>
                                                            </>
                                                            )
                                                        ) : null
                                                    }                                   
                                                    </>
                                                }
                                                title={<Typography variant="h5">{datas.values.label}</Typography>}
                                                // subheader={}
                                                />
                                                <CardContent>
                                                    <Box>
                                                        <Grid container spacing={2}> 
                                                            {/* <TextField
                                                            disabled={!edit[datas.values._id]}
                                                            type="text"
                                                            label="Name"
                                                            onChange={datas.handleChange}
                                                            onBlur={datas.handleBlur}
                                                            value={datas.values.name}
                                                            name="name"
                                                            />
                                                            {datas.errors.name && <div id="fb_name">{datas.errors.name}</div>} */}

                                                            <Grid item xs={6}> 
                                                                <TextField
                                                                fullWidth
                                                                disabled={!edit[datas.values._id]}
                                                                type="text"
                                                                label="Value"
                                                                onChange={datas.handleChange}
                                                                onBlur={datas.handleBlur}
                                                                value={datas.values.value || ''}
                                                                name="value"
                                                                />
                                                                {datas.errors.value && <div id="fb_value">{datas.errors.value}</div>}
                                                            </Grid>

                                                        </Grid>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Form>
                                    )}
                                    </Formik> 
                                </Grid> 
                            ))}
                            </>
                            </Grid> 
                        ) : <CircularProgress /> }                
                        </CardContent>
                    </Card>
                </div>

                <div className="Animal_route_of_administration">
                    <Card key=" main_animal_route_administration" sx={{mt:2}}>
                        <CardHeader
                            avatar={<InsightsIcon fontSize="large" />}
                            title={<Typography variant="h4">Animal Route of Administration</Typography>}
                        />
                        <CardContent> 
                            {!loading.animal_route_administration ? (
                                <Grid container spacing={2}> 
                                <>
                                {animal_route_administration.map((a_r_a, index) => ( 
                                    <Grid key={a_r_a.name} item xs={6}>      
                                    <Formik
                                    enableReinitialize={true}
                                    initialValues={a_r_a}
                                    //validationSchema={ValidationSchema}
                                    onSubmit={(values, actions) => {
                                        handleSubmitAnimalRouteAdministration(values);
                                        handleClear(values._id);
                                    }}
                                    >
                                    {datas => (
                                        <Form onSubmit={datas.handleSubmit}>
                                            <Card>
                                                <CardHeader
                                                avatar={
                                                    <Groups2Icon fontSize="large" />
                                                }
                                                action={
                                                    <>
                                                    {
                                                        allowed ? (
                                                            !edit[datas.values._id] ? (
                                                                <IconButton aria-label="edit" onClick={() => {handleEdit(datas.values._id);}}>
                                                                    <EditIcon />
                                                                </IconButton>
                                                            ) : (
                                                            <>
                                                                <IconButton aria-label="done" type="submit">
                                                                    <DoneIcon />
                                                                </IconButton>

                                                                <IconButton aria-label="clear" onClick={() => {datas.resetForm(); handleClear(datas.values._id);}}>
                                                                    <ClearIcon />
                                                                </IconButton>
                                                            </>
                                                            )
                                                        ) : null
                                                    }                                   
                                                    </>
                                                }
                                                title={<Typography variant="h5">{datas.values.label}</Typography>}
                                                // subheader={}
                                                />
                                                <CardContent>
                                                    <Box>
                                                        <Grid container spacing={2}> 
                                                            {/* <TextField
                                                            disabled={!edit[datas.values._id]}
                                                            type="text"
                                                            label="Name"
                                                            onChange={datas.handleChange}
                                                            onBlur={datas.handleBlur}
                                                            value={datas.values.name}
                                                            name="name"
                                                            />
                                                            {datas.errors.name && <div id="fb_name">{datas.errors.name}</div>} */}

                                                            <Grid item xs={6}> 
                                                                <TextField
                                                                fullWidth
                                                                disabled={!edit[datas.values._id]}
                                                                type="text"
                                                                label="Value"
                                                                onChange={datas.handleChange}
                                                                onBlur={datas.handleBlur}
                                                                value={datas.values.value || ''}
                                                                name="value"
                                                                />
                                                                {datas.errors.value && <div id="fb_value">{datas.errors.value}</div>}
                                                            </Grid>

                                                        </Grid>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Form>
                                    )}
                                    </Formik> 
                                </Grid> 
                            ))}
                            </>
                            </Grid> 
                        ) : <CircularProgress /> }                
                        </CardContent>
                    </Card>
                </div>
   
                <div className="Plateforms">
                    <Card key="main_plateforms" sx={{mt:2}}>
                        <CardHeader
                            avatar={<ExposureIcon fontSize="large" />}
                            title={<Typography variant="h4">Platform Exposure</Typography>}
                            action={
                                <>
                                {  
                                    allowed ? ( 
                                        !isNewMode() ? (
                                            <IconButton aria-label="new" onClick={() => { handleNewPlatform(); }}>
                                                <AddIcon />
                                            </IconButton>
                                        ) : null
                                    ) : null
                                }
                                </>
                            }
                        />
                        <CardContent>
                            {!loading.plateforms ? (
                            <Grid container spacing={2}>                     
                            <>
                            {plateforms.map((plateform, index) => (
                                <Grid key={plateform.name} item xs={4}> 
                                    <Formik
                                    enableReinitialize={true}
                                    initialValues={plateform}
                                    //validationSchema={ValidationSchema}
                                    onSubmit={(values, actions) => {
                                        handleSubmitPlateform(values);
                                        handleClear(values._id);
                                    }}
                                    >
                                    {props => (
                                        <Form onSubmit={props.handleSubmit}>
                                            <Card>
                                                <CardHeader
                                                // avatar={
                                                    
                                                // }
                                                action={
                                                    <>
                                                    {
                                                        allowed ? (
                                                            !isPlatformEditable(props.values) ? (
                                                                <IconButton aria-label="edit" onClick={() => {handleEdit(props.values._id);}}>
                                                                    <EditIcon />
                                                                </IconButton>
                                                            ) : (
                                                            <>
                                                                <IconButton aria-label="done" type="submit">
                                                                    <DoneIcon />
                                                                </IconButton>

                                                                <IconButton aria-label="clear" onClick={() => {props.resetForm(); handleClear(props.values.name);}}>
                                                                    <ClearIcon />
                                                                </IconButton>

                                                                {!isNullUndefinedEmpty(props.values._id) ? (
                                                                        <IconButton aria-label="clear" onClick={() => {handleDelete(props.values);}}>
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    ) : null
                                                                }
                                                            </>
                                                            )
                                                        ) : null
                                                    }                                    
                                                    </>
                                                }
                                                title={<Typography variant="h5">{props.values.name}</Typography>}
                                                // subheader={}
                                                />
                                                <CardContent>
                                                    <Box>
                                                        <Grid container spacing={1}> 
                                                            <Grid item xs={6}> 
                                                                <TextField
                                                                disabled={!isPlatformEditable(props.values)}
                                                                type="text"
                                                                label="Name"
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                value={props.values.name}
                                                                name="name"
                                                                />
                                                                {props.errors.name && <div id="fb_name">{props.errors.name}</div>}
                                                            </Grid>

                                                            <Grid item xs={6}> 
                                                                <TextField
                                                                fullWidth
                                                                disabled={!isPlatformEditable(props.values)}
                                                                type="text"
                                                                label="Product daily max item use (item/day)"
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                value={props.values.product_daily_max_item_use}
                                                                name="product_daily_max_item_use"
                                                                />
                                                                {props.errors.product_daily_max_item_use && <div id="fb_product_daily_max_item_use">{props.errors.product_daily_max_item_use}</div>}
                                                            </Grid>

                                                            <Grid item xs={6}>                                                    
                                                                <TextField
                                                                fullWidth
                                                                disabled={!isPlatformEditable(props.values)}
                                                                type="text"
                                                                label="Mass item (mg/item)"
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                value={props.values.mass_item}
                                                                name="mass_item"
                                                                />
                                                                {props.errors.mass_item && <div id="fb_mass_item">{props.errors.mass_item}</div>}
                                                            </Grid>


                                                            <Grid item xs={6}>
                                                                <TextField
                                                                fullWidth
                                                                disabled={!isPlatformEditable(props.values)}
                                                                type="text"
                                                                label="Product daily use level (mg/day)"
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                value={props.values.product_daily_max_unit_use}
                                                                name="product_daily_max_unit_use"
                                                                />
                                                                {props.errors.product_daily_max_unit_use && <div id="fb_product_daily_max_unit_use">{props.errors.product_daily_max_unit_use}</div>}
                                                            </Grid>


                                                            <Grid item xs={6}>
                                                                <TextField
                                                                fullWidth
                                                                disabled={!isPlatformEditable(props.values)}
                                                                type="text"
                                                                label="Transfer ratio"
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                value={props.values.transfer_ratio}
                                                                name="transfer_ratio"
                                                                />
                                                                {props.errors.transfer_ratio && <div id="fb_transfer_ratio">{props.errors.transfer_ratio}</div>}
                                                            </Grid>

                                                            {/* <Grid item xs={6}>
                                                                <TextField
                                                                fullWidth
                                                                disabled={!isPlatformEditable(props.values)}
                                                                type="text"
                                                                label="Safety factor for dose accumulation"
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                value={props.values.safety_factor}
                                                                name="safety_factor"
                                                                />
                                                                {props.errors.safety_factor && <div id="fb_safety_factor">{props.errors.safety_factor}</div>}
                                                            </Grid> */}

                                                            <Grid item xs={6}>
                                                                <TextField
                                                                fullWidth
                                                                disabled={!isPlatformEditable(props.values)}
                                                                type="text"
                                                                label="Contact surface (cm²)"
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                value={props.values.contact_surface}
                                                                name="contact_surface"
                                                                />
                                                                {props.errors.contact_surface && <div id="fb_contact_surface">{props.errors.contact_surface}</div>}
                                                            </Grid>

                                                            <Grid item xs={6}>
                                                                <TextField
                                                                fullWidth
                                                                disabled={!isPlatformEditable(props.values)}
                                                                type="text"
                                                                label="SAF"
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                value={props.values.SAF}
                                                                name="SAF"
                                                                />
                                                                {props.errors.SAF && <div id="fb_SAF">{props.errors.SAF}</div>}
                                                            </Grid>

                                                            <Grid item xs={6}>
                                                                <TextField
                                                                fullWidth
                                                                disabled={!isPlatformEditable(props.values)}
                                                                type="text"
                                                                label="Retention Rate"
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                value={props.values.retention_rate}
                                                                name="retention_rate"
                                                                />
                                                                {props.errors.retention_rate && <div id="fb_retention_rate">{props.errors.retention_rate}</div>}
                                                            </Grid>
                                                            
                                                            <Grid item xs={6}>
                                                                <Field
                                                                    type="checkbox"
                                                                    name="inhalation"
                                                                    as={FormControlLabel}
                                                                    control={<Checkbox />}
                                                                    label="Inhalation"
                                                                    disabled={!isPlatformEditable(props.values)}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={6}>
                                                                <Field
                                                                    type="checkbox"
                                                                    name="oral"
                                                                    as={FormControlLabel}
                                                                    control={<Checkbox />}
                                                                    label="Oral"
                                                                    disabled={!isPlatformEditable(props.values)}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Form>

                                    )}
                                    </Formik>
                                </Grid>
                            ))}
                            </>
                            </Grid>
                            ) : <CircularProgress /> }
                        </CardContent>
                    </Card>
                </div>
        </>    
    );
}