import './dashboard.css';
import { API } from "./../../services/api/api";
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect } from 'react';
import { Card, CardHeader, CardContent, Chip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import SpeedIcon from '@mui/icons-material/Speed';
import { Typography } from '@mui/material';
import { CognitoAuth } from '../../auth/cognito';
import { calculateCompletionPercentage, isObjectEmpty } from '../../utils/utils';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';


const getChipColor = (type, status) => {
  return type.find(x => x.name === status)?.color;
};

export default function Dashboard({ user, onSelect }) {

  const navigate = useNavigate();

  const [rows, setRows] = React.useState([]);
  const [status, setStatus] = React.useState([]);
  const [w_status, setWorkflowStatus] = React.useState([]);
  const [loading, setLoading] = React.useState(true); // Add loading state


  useEffect(() => {

    const fetchData = async () => {
      try {
        if (isObjectEmpty(user)) {
          user = await CognitoAuth.getCurrentUser();
        }

        const result = await API.getFlaggedComponents(user.username);
        // console.log(result);
        const mapped = result ? result : [];
        console.log(mapped);
        setRows(mapped);
      } catch {
        toast.warn("WARNING! Impossible to fetch data from category!");
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };

    const fetchDataStatus = async () => {
      try {
        const result = await API.getStatus("approval");
        const map = result;
        setStatus(map);
      } catch (ex) {
        toast.warn("WARNING! Impossible to parse status!");
      }
    };

    const fetchWorkflowStatus = async () => {
      try {
        const result = await API.getStatus("workflow");
        const map = result;
        setWorkflowStatus(map);
      } catch (ex) {
        toast.warn("WARNING! Impossible to parse status!");
      }
    };

    fetchData();
    fetchDataStatus();
    fetchWorkflowStatus();
  }, []);

  const handleRowClick = async (params) => {
    const substance = await API.getPropertyData("_key", params.row._key);
    onSelect(substance[0]);
    navigate("/ids/" + substance[0]._key);
  };

  const getWorkflowLevel = (status) => {
    const workflow = w_status.find(x => x.name === status);
    return workflow ? workflow.level : 0;
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      headerClassName: 'super-app-theme--header',
      //   width: 150,
      flex: 1,
      editable: false,
    },
    {
      field: 'cas',
      headerName: 'CAS',
      headerClassName: 'super-app-theme--header',
      //   width: 150,
      flex: 1,
      editable: false,
    },
    {
      field: 'workflow_status',
      headerName: 'Workflow',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      renderCell: (params) => (
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          {params.value ? <Chip label={params.value} style={{ backgroundColor: getChipColor(w_status, params.value), color: '#FFFFFF' }} /> : '-'}
        </div>
      ),
      flex: 1,
      editable: false,
      sortComparator: (v1, v2) => getWorkflowLevel(v1) - getWorkflowLevel(v2),
    },
    {
      field: 'approval_status',
      headerName: 'Approval',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      renderCell: (params) => (
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          {params.value ? <Chip label={params.value} style={{ backgroundColor: getChipColor(status, params.value), color: '#FFFFFF' }} /> : '-'}
        </div>
      ),
      flex: 1,
      editable: false,
    },
    {
      field: 'completion',
      headerName: 'Completion',
      headerClassName: 'super-app-theme--header',
      //   width: 150,
      flex: 1,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress variant="determinate" value={Math.round(params.row.completion)} />
          <Box
            sx={{
              top: 1,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="caption" component="div" color="text.secondary">
              {`${Math.round(params.row.completion)}%`}
            </Typography>
          </Box>
        </Box>
      ),
    },
  ];

  return (
    <>
      {rows ? (
        <Box sx={{
          '& .super-app-theme--header': {
            backgroundColor: 'rgba(207,222,208, 0.55)',
          },
        }}>
          <Card>
            <CardHeader
              avatar={
                <SpeedIcon sx={{ width: '50px', height: '50px' }} />
              }
              title={<Typography style={{ textAlign: 'left' }} variant="h5">My Dashboard</Typography>}
            />
            <CardContent>
              <DataGrid
                getRowId={(row) => row._key}
                rows={rows}
                onRowClick={handleRowClick}
                autoHeight
                columns={columns}
                loading={loading}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                disableRowSelectionOnClick
              />
            </CardContent>
          </Card>
        </Box>
      ) : (
        <CircularProgress />
      )}
    </>
  );
}