import './report.css';
import * as React from 'react';
import { useEffect } from 'react';
import { API } from '../../services/api/api';
import { Autocomplete, Box, Button, Card, CardContent, CardHeader, CircularProgress, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, styled, TextField, Typography } from '@mui/material';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { toast } from 'react-toastify';
import { isNullUndefinedEmpty, traverseAndFlattenCustom } from '../../utils/utils';
import * as XLSX from 'xlsx-js-style';
import { saveAs } from 'file-saver';
import { calculateADE, calculateFinalMRL, calculateLocalSensitizationMRL, calculateMRL, calculateModifiedPoD } from '../../utils/calculation';
import AddIcon from '@mui/icons-material/Add';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { CASNumberModal } from '../../component/casmodal';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function Report({ user }) {


  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [values, setValues] = React.useState([]);
  const [pop_conc, setPopulationConcerned] = React.useState([]);
  const [population_concerned, setPopulationConcernedData] = React.useState([]);
  const [plateform, setPlateform] = React.useState([]);
  const [plateforms, setPlateformsData] = React.useState([]);
  const [cramer_class, setCramerClass] = React.useState([]);
  const [animal_route_administrations, setAnimalRouteAdministration] = React.useState([]);
  const [human_route_exposure, setHumanRouteExposure] = React.useState([]);
  const [uncertainty_factors, setUncertaintyFactors] = React.useState([]);
  const [animals, setAnimalsData] = React.useState([]);
  const [dose_descriptors, setDoseDescriptor] = React.useState([]);
  const [study_test_methods, setStudyTestMethod] = React.useState([]);
  const [glp_compliance, setGLPCompliance] = React.useState([]);
  const [study_durations, setStudyDuration] = React.useState([]);
  const [severity, setSeverity] = React.useState([]);
  const [units, setUnits] = React.useState([]);
  const [type_effects, setTypeEffects] = React.useState([]);
  const [cont, setContainSelect] = React.useState([]);

  const [modalOpen, setModalOpen] = React.useState(false);

  //const [loading, setLoading] = React.useState({});

  const loading = open && options.length === 0;

  const handleInputChange = async value => {
    const substances = await API.getFilteredData(value.target.value);
    setOptions(substances);
  };

  const getLabel = (option) => {
    if (option.name && option.cas) {
      return option.name + " (" + option.cas + ")";
    }
  }

  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleUpload = (event) => {
    const file = event.target.files[0];
    console.log(file);
    if (file && file.name.endsWith('.xlsx')) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const worksheet = workbook.Sheets[workbook.SheetNames];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 }).flat();
        //console.log(jsonData);

        if (jsonData[0] === 'cas') {
          const casArray = jsonData.slice(1).map(row => row.trim());
          casArray.forEach((cas) => {
            var tmp = options.find(o => o.cas === cas);
            // var tmp = options.filter(o => o.cas === cas);

            if (tmp && !values.find(v => v._key === tmp._key)) {
              setValues(prevValues => [...prevValues, tmp]);
              // setValues(tmp);
            }
          });
        } else {
          toast.error('The first column header is not "cas"');
        }
      };
      reader.readAsArrayBuffer(file);
    } else {
      toast.error('Please upload a valid .xlsx file');
    }
  };

  const handleOk = (casArray) => {
    casArray.forEach((cas) => {
      var tmp = options.find(o => o.cas === cas);
      //var tmp = options.filter(o => o.cas === cas);

      if (!isNullUndefinedEmpty(tmp) && !values.find(v => v._key === tmp._key)) {
        setValues(prevValues => [...prevValues, tmp]);
        //setValues(tmp);  
      }
    });
    //console.log(values);
  };

  const handleSelect = (event, value) => {
    //console.log(event);
    setValues(value);
  };

  const handlePopulationConcerned = (event) => {
    setPopulationConcerned(event.target.value);
  };

  const handlePlateform = (event) => {
    setPlateform(event.target.value);
  };

  const handleSubmit = () => {
    try {
      const workbook = XLSX.utils.book_new();
      plateform.forEach((e, i) => {
        let array = [];

        values.forEach((el, index) => {

          //console.log(plateform);
          if (plateforms.find(o => o.name === e)?.inhalation === true) {
            el.modified_POD_inhalation = calculateModifiedPoD("inhalation", el, population_concerned, pop_conc, animals, animal_route_administrations, human_route_exposure);
            el.ADE_inhalation = calculateADE("inhalation", el, population_concerned, pop_conc, animal_route_administrations, human_route_exposure, uncertainty_factors, animals);
            el.general_toxicity_MRL_inhalation = calculateMRL("inhalation", el, plateforms, e, animals, population_concerned, pop_conc, animal_route_administrations, human_route_exposure, uncertainty_factors, cont);
            el.local_sensitization_MRL_inhalation = calculateLocalSensitizationMRL("inhalation", el, plateforms, e, cont);
            el.final_MRL_inhalation = calculateFinalMRL("inhalation", el, plateforms, e, animals, population_concerned, pop_conc, animal_route_administrations, human_route_exposure, uncertainty_factors, cont);
          }

          // console.log(el.final_MRL_inhalation);

          if (plateforms.find(o => o.name === e)?.oral === true) {
            el.modified_POD_oral = calculateModifiedPoD("oral", el, population_concerned, pop_conc, animals, animal_route_administrations, human_route_exposure);;
            el.ADE_oral = calculateADE("oral", el, population_concerned, pop_conc, animal_route_administrations, human_route_exposure, uncertainty_factors, animals);;
            el.general_toxicity_MRL_oral = calculateMRL("oral", el, plateforms, e, animals, population_concerned, pop_conc, animal_route_administrations, human_route_exposure, uncertainty_factors, cont);
            el.local_sensitization_MRL_oral = calculateLocalSensitizationMRL("oral", el, plateforms, e, cont);
            el.final_MRL_oral = calculateFinalMRL("oral", el, plateforms, e, animals, population_concerned, pop_conc, animal_route_administrations, human_route_exposure, uncertainty_factors, cont);
          }
        });

        // console.log(el.final_MRL_oral);


        values.forEach((el, index) => {
          let tmp = {};
          traverseAndFlattenCustom(el, tmp);
          array.push(tmp);
        });

        console.log(array);

        const worksheet = XLSX.utils.json_to_sheet(array);

        // Apply styles to highlight columns
        const range = XLSX.utils.decode_range(worksheet['!ref']);
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const address = XLSX.utils.encode_col(C) + "1";
          if (worksheet[address] && (worksheet[address].v === "final_MRL_inhalation" || worksheet[address].v === "final_MRL_oral")) {
            for (let R = range.s.r; R <= range.e.r; ++R) {
              const cell_address = XLSX.utils.encode_cell({ c: C, r: R });         
              if (!worksheet[cell_address]) worksheet[cell_address] = {};
              if (!worksheet[cell_address].s) worksheet[cell_address].s = {};
              worksheet[cell_address].s.fill = {
                patternType: "solid",
                fgColor: { rgb: "FFFF00" } // Yellow color
              };
              //console.log(worksheet[cell_address]);
            }
          }
        }

        XLSX.utils.book_append_sheet(workbook, worksheet, e);
      })

      // Buffer to store the generated Excel file
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

      saveAs(blob, "data.xlsx");
    }
    catch (ex) {
      console.log(ex);
      toast.error("Error during the generation of the report !")
    }
  }

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }

    const fetchCompounds = async () => {
      let result = await API.getFilteredData(null);
      setOptions(result);
    }

    const fetchPlateformsData = async () => {
      try {
        // setLoading(loading => ({...loading, ...{['plateforms'] : true}}));
        let result = await API.getPlateforms();
        let map = result;
        setPlateformsData(map);
        // setLoading(loading => ({...loading, ...{['plateforms'] : false}}));
      } catch (ex) {
        toast.warn("WARN ! impossible to parse plateforms !");
      }
    };

    const fetchPopulationConcernedData = async () => {
      try {
        // setLoading(loading => ({...loading, ...{['population_concerned'] : true}}));
        let result = await API.getPopulationConcerned();
        let map = result;
        setPopulationConcernedData(map);
        // setLoading(loading => ({...loading, ...{['population_concerned'] : false}}));
      } catch (ex) {
        toast.warn("WARN ! impossible to parse population_concerned !");
      }
    };

    const fetchAnimalsData = async () => {
      try {
        // setLoading(loading => ({...loading, ...{['animals'] : true}}));
        let result = await API.getAnimals();
        let map = result;
        setAnimalsData(map);
        // setLoading(loading => ({...loading, ...{['animals'] : false}}));
      } catch (ex) {
        toast.warn("WARN ! impossible to parse animals !");
      }
    };

    const fetchUncertaintyFactors = async () => {
      try {
        // setLoading(loading => ({...loading, ...{['uncertainty_factors'] : true}}));
        let result = await API.getUncertaintyFactors();
        let map = result;
        setUncertaintyFactors(map);
        // setLoading(loading => ({...loading, ...{['uncertainty_factors'] : false}}));
      } catch (ex) {
        toast.warn("WARN ! impossible to parse uncertainty factors !");
      }
    };

    const fetchCramerClass = async () => {
      try {
        // setLoading(loading => ({...loading, ...{['cramer_class'] : true}}));
        let result = await API.getCramerClass();
        let map = result;
        setCramerClass(map);
        // setLoading(loading => ({...loading, ...{['cramer_class'] : false}}));
      } catch (ex) {
        toast.warn("WARN ! impossible to parse Cramer CLASS !");
      }
    };

    const fetchHumanRouteExposure = async () => {
      try {
        // setLoading(loading => ({...loading, ...{['human_route_exposure'] : true}}));
        let result = await API.getHumanRouteExposure();
        let map = result;
        setHumanRouteExposure(map);
        // setLoading(loading => ({...loading, ...{['human_route_exposure'] : false}}));
      } catch (ex) {
        toast.warn("WARN ! impossible to parse Human Route of Exposure !");
      }
    };

    const fetchAnimalRouteAdministration = async () => {
      try {
        // setLoading(loading => ({...loading, ...{['animal_route_administration'] : true}}));
        let result = await API.getAnimalRouteAdministration();
        let map = result;
        setAnimalRouteAdministration(map);
        // setLoading(loading => ({...loading, ...{['animal_route_administration'] : false}}));
      } catch (ex) {
        toast.warn("WARN ! impossible to parse Animal Route of Administration !");
      }
    };

    const fetchDoseDescriptor = async () => {
      try {
        // setLoading(loading => ({...loading, ...{['dose_descriptor'] : true}}));
        let result = await API.getDoseDescriptor();
        let map = result;
        setDoseDescriptor(map);
        // setLoading(loading => ({...loading, ...{['dose_descriptor'] : false}}));
      } catch (ex) {
        toast.warn("WARN ! impossible to parse dose descriptors !");
      }
    };

    const fetchStudyTestMethod = async () => {
      try {
        // setLoading(loading => ({...loading, ...{['study_test_method'] : true}}));
        let result = await API.getStudyTestMethod();
        let map = result;
        setStudyTestMethod(map);
        // setLoading(loading => ({...loading, ...{['study_test_method'] : false}}));
      } catch (ex) {
        toast.warn("WARN ! impossible to parse study test methods !");
      }
    };

    const fetchGLPCompliance = async () => {
      try {
        // setLoading(loading => ({...loading, ...{['glp_compliance'] : true}}));
        let result = await API.getGLPCompliance();
        let map = result;
        setGLPCompliance(map);
        // setLoading(loading => ({...loading, ...{['glp_compliance'] : false}}));
      } catch (ex) {
        toast.warn("WARN ! impossible to parse glp compliance !");
      }
    };

    const fetchStudyDuration = async () => {
      try {
        // setLoading(loading => ({...loading, ...{['study_duration'] : true}}));
        let result = await API.getStudyDuration();
        let map = result;
        setStudyDuration(map);
        // setLoading(loading => ({...loading, ...{['study_duration'] : false}}));
      } catch (ex) {
        toast.warn("WARN ! impossible to parse study duration !");
      }
    };

    const fetchSeverity = async () => {
      try {
        // setLoading(loading => ({...loading, ...{['severity'] : true}}));
        let result = await API.getSeverity();
        let map = result;
        setSeverity(map);
        // setLoading(loading => ({...loading, ...{['severity'] : false}}));
      } catch (ex) {
        toast.warn("WARN ! impossible to parse severity !");
      }
    };

    const fetchUnits = async () => {
      try {
        // setLoading(loading => ({...loading, ...{['units'] : true}}));
        let result = await API.getUnits();
        let map = result;
        setUnits(map);
        // setLoading(loading => ({...loading, ...{['units'] : false}}));
      } catch (ex) {
        toast.warn("WARN ! impossible to parse units !");
      }
    };

    const fetchTypeEffects = async () => {
      try {
        // setLoading(loading => ({...loading, ...{['type_effects'] : true}}));
        let result = await API.getTypeEffects();
        let map = result;
        setTypeEffects(map);
        // setLoading(loading => ({...loading, ...{['type_effects'] : false}}));
      } catch (ex) {
        toast.warn("WARN ! impossible to parse type effects !");
      }
    };

    const fetchDataSelectContain = async () => {
      try {
        const result = await API.getCompoundsSelect("basic");
        const map = result;
        // console.log(map);
        setContainSelect(map);
        //setDataGridColumns(map);

      } catch (ex) {
        toast.warn("WARNING ! impossible to parse containers !");
      }
    };

    fetchCompounds();
    fetchPopulationConcernedData();
    fetchPlateformsData();
    fetchAnimalsData();
    fetchUncertaintyFactors();
    fetchCramerClass();
    fetchHumanRouteExposure();
    fetchAnimalRouteAdministration();
    fetchDoseDescriptor();
    fetchStudyTestMethod();
    fetchGLPCompliance();
    fetchStudyDuration();
    fetchSeverity();
    fetchUnits();
    fetchTypeEffects();
    fetchDataSelectContain();

  }, [open]);

  return (
    <div className='Report'>
      <Box sx={{
        '& .super-app-theme--header': {
          backgroundColor: 'rgba(207,222,208, 0.55)',
        },
      }}>
        <Card>
          <CardHeader
            avatar={
              <SummarizeIcon sx={{ width: '50px', height: '50px' }} />
            }
            title={<Typography style={{ textAlign: 'left' }} variant="h5">Export as Report</Typography>}
          />
          <CardContent>
            <Grid container spacing={2} justify="center">
              <Grid item xs={5}>
                <Box sx={{ width: 1, display: 'flex', alignItems: 'flex-end' }}>
                  <Autocomplete
                    id="ac"
                    multiple
                    clearOnEscape
                    fullWidth
                    filterSelectedOptions
                    // style={{ width: 400 }}
                    open={open}
                    onOpen={() => {
                      setOpen(true);
                    }}
                    onClose={() => {
                      setOpen(false);
                    }}
                    isOptionEqualToValue={(option, value) => option._key === value._key}
                    getOptionLabel={option => getLabel(option)}
                    options={options}
                    loading={loading}
                    onInputChange={handleInputChange}
                    value={values}
                    onChange={handleSelect}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Substances..."
                        variant="outlined"
                        // onChange={(ev,nv) => {
                        //   // dont fire API if the user delete or not entered anything
                        //   if (ev.target.value !== "" || ev.target.value !== null) {
                        //     handleInputChange(ev.target.value);
                        //   }
                        // }}

                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                  <IconButton onClick={handleOpenModal} sx={{ mb: 1 }}>
                    <AddIcon />
                  </IconButton>
                  <IconButton component="label" sx={{ mb: 1 }}>
                    <CloudUploadIcon />
                    <VisuallyHiddenInput
                      type="file"
                      onChange={(event) => handleUpload(event)}
                    />
                  </IconButton>
                  <CASNumberModal open={modalOpen} onClose={handleCloseModal} onOk={handleOk} />
                </Box>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id="population_concerned">Population concerned</InputLabel>
                  <Select
                    labelId="population_concerned"
                    id="population_concerned"
                    name="population_concerned"
                    value={pop_conc}

                    onChange={handlePopulationConcerned}
                  >
                    {!isNullUndefinedEmpty(population_concerned) ? population_concerned.map((x) => (
                      <MenuItem
                        id={x.name}
                        key={x.name}
                        value={x.name}
                      // style={getStyles(name, props.values.approval_status, theme)}
                      >
                        {x.label}
                      </MenuItem>
                    )) : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id="plateforms">Platform</InputLabel>
                  <Select
                    labelId="plateforms"
                    id="plateforms"
                    name="plateforms"
                    multiple
                    value={plateform}
                    onChange={handlePlateform}
                  >
                    {!isNullUndefinedEmpty(plateforms) ? plateforms.map((x) => (
                      <MenuItem
                        id={x.name}
                        key={x.name}
                        value={x.name}
                      // style={getStyles(name, props.values.approval_status, theme)}
                      >
                        {x.name}
                      </MenuItem>
                    )) : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={1}>
                <Box sx={{ justifyContent: "center" }}>
                  <Button sx={{ minHeight: "56px" }} fullWidth variant="outlined" size="large" onClick={() => { handleSubmit() }}>EXPORT</Button>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
}